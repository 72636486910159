import { makeStyles } from "@material-ui/core/styles";

const useStylesChip = makeStyles((theme) => ({
  colorChip1: {
    color: theme.palette.filter1.main,
  },
  colorChipBorder1: {
    borderColor: theme.palette.filter1.main,
    borderWidth: 1,
    borderStyle: "solid",
  },
  colorChip2: {
    color: theme.palette.filter2.main,
  },
  colorChipBorder2: {
    borderColor: theme.palette.filter2.main,
    borderWidth: 1,
    borderStyle: "solid",
  },
  colorChip3: {
    color: theme.palette.filter3.main,
  },
  colorChipBorder3: {
    borderColor: theme.palette.filter3.main,
    borderWidth: 1,
    borderStyle: "solid",
  },
  colorChip4: {
    color: theme.palette.filter4.main,
  },
  colorChipBorder4: {
    borderColor: theme.palette.filter4.main,
    borderWidth: 1,
    borderStyle: "solid",
  },
  colorChipAutocomplete4: {
    color: theme.palette.filter4.main,
    borderColor: theme.palette.filter4.main,
    borderWidth: 1,
    borderStyle: "solid",
    backgroundColor: theme.palette.primary.main,
    "& .MuiChip-deleteIcon": {
      color: theme.palette.filter4.main,
    },
  },
  colorChip5: {
    color: theme.palette.filter5.main,
  },
  colorChipBorder5: {
    borderColor: theme.palette.filter5.main,
    borderWidth: 1,
    borderStyle: "solid",
  },
  colorChip6: {
    color: theme.palette.filter6.main,
  },
  colorChipBorder6: {
    borderColor: theme.palette.filter6.main,
    borderWidth: 1,
    borderStyle: "solid",
  },
  colorSecondaryChip6: {
    color: theme.palette.filter6.main,
    "&$checked": {
      color: theme.palette.filter6.main,
    },
  },
  colorChip7: {
    color: theme.palette.filter7.main,
  },
  colorChipBorder7: {
    borderColor: theme.palette.filter7.main,
    borderWidth: 1,
    borderStyle: "solid",
  },
  colorChip9: {
    color: theme.palette.filter9.main,
  },
  colorChipBorder9: {
    borderColor: theme.palette.filter9.main,
    borderWidth: 1,
    borderStyle: "solid",
  },
  colorChip10: {
    color: theme.palette.filter10.main,
  },
  colorChipBorder10: {
    borderColor: theme.palette.filter10.main,
    borderWidth: 1,
    borderStyle: "solid",
  },
  colorChip11: {
    color: theme.palette.filter11.main,
  },
  colorChipBorder11: {
    borderColor: theme.palette.filter11.main,
    borderWidth: 1,
    borderStyle: "solid",
  },
}));

export default useStylesChip;
