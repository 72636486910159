import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import "date-fns";
import moment from "moment";
import "moment/locale/el";
import { useRouter } from "next/router";
import { useState } from "react";
import "react-day-picker/lib/style.css";
import Draggable from "react-draggable";
import useStylesChip from "../../src/theme/stylesChip";
import DatePickerWithClearIcon from "../dates/DatePickerWithClearIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    cursor: "move",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
    width: 520,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexWrap: "nowrap",
  },
  date: {
    display: "flex",
    flex: 1,
    width: "100%",
    justifyContent: "center",
    padding: 0,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: 0,
    alignItems: "flex-end",
  },
  submitButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main,
    },
  },
  disabledButton: {
    backgroundColor: theme.palette.primary.main,
  },
  cancelButton: {
    color: theme.palette.secondary.light,
  },
  listbox: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
  menuItemRoot: {
    whiteSpace: "pre-wrap",
    "&$menuItemSelected, &$menuItemSelected:hover": {
      // backgroundColor: theme.palette.secondary.main,
      // color: theme.palette.primary.main,
      /**
       * TODO: may apply this minimal styling and remove
       * all colors from selected, hover
       */
      fontWeight: 430,
    },
    "&$menuItemSelected:hover": {
      // backgroundColor: theme.palette.secondary.light,
      // color: theme.palette.primary.main,
      fontWeight: 400,
    },
  },
  menuItemSelected: {},
  radio: {
    margin: theme.spacing(1),
  },
  radioGroup: {
    display: "inline",
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-modal-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Modal(props) {
  const router = useRouter();
  const classesChip = useStylesChip();

  const {
    submitFilters,
    handleState,
    handleLegalForm,
    handleStartDateChange,
    handleEndDateChange,
    handleIncomeType,
    handlePayment,
    handleStatus,
    handlePerifereia,
    handleTransType,
    handleBlock,
    state,
    status,
    perifereia,
    transType,
    legalForm,
    blockSer,
    states,
    gemiStatus,
    gemiPerifereia,
    gemiTransType,
    legalForms,
    incomeType,
    incomeTypes,
    payment,
    payments,
    block,
    selectedStartDate,
    selectedEndDate,
  } = props;
  const { open, handleClose } = props;
  const [errorOnStartDate, setErrorOnStartDate] = useState(false);
  const [errorOnEndDate, setErrorOnEndDate] = useState(false);
  const [errorOnDates, setErrorOnDates] = useState(false);
  const classes = useStyles();

  moment.locale("el");

  const handleErrorOnStartDate = (error) => {
    if (error.length == 0) {
      setErrorOnStartDate(false);
    } else {
      setErrorOnStartDate(true);
    }
    handleErrorOnDates();
  };

  const handleErrorOnEndDate = (error) => {
    if (error.length == 0) {
      setErrorOnEndDate(false);
    } else {
      setErrorOnEndDate(true);
    }
    handleErrorOnDates();
  };

  const handleErrorOnDates = () => {
    if (errorOnEndDate || errorOnStartDate) {
      setErrorOnDates(true);
    } else {
      setErrorOnDates(false);
    }
  };

  const handleDelete = (index, id) => () => {
    let array;
    switch (index) {
      case 0:
        array = incomeType.filter((option) => option !== id);
        handleIncomeType(array);
        break;
      case 1:
        array = payment.filter((option) => option !== id);
        handlePayment(array);
        break;
      case 2:
        array = state.filter((option) => option !== id);
        handleState(array);
        break;
      case 3:
        array = status.filter((option) => option !== id);
        handleStatus(array);
        break;
      case 4:
        array = legalForm.filter((option) => option !== id);
        handleLegalForm(array);
        break;
      case 5:
        array = perifereia.filter((option) => option !== id);
        handlePerifereia(array);
        break;
      case 6:
        array = transType.filter((option) => option !== id);
        handleTransType(array);
        break;
      case 7:
        array = blockSer.filter((option) => option !== id);
        handleBlock(array);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-modal-title"
        style={{ cursor: "move" }}
        className={classes.root}
        id="dialog"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-modal-title">
          Φίλτρα
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            overflowX: "hidden",
          }}
          dividers
        >
          {router.pathname.includes("incomebytype") ||
          router.pathname.includes("gemicases") ? (
            <Container id="date" className={classes.date}>
              <DatePickerWithClearIcon
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                selectedEndDate={selectedEndDate}
                selectedStartDate={selectedStartDate}
                handleErrorOnEndDate={handleErrorOnEndDate}
                handleErrorOnStartDate={handleErrorOnStartDate}
                labelFrom="Από"
                labelTo="Έως"
                fullWidth={true}
              />
            </Container>
          ) : (
            <></>
          )}
          {router.pathname.includes("allincome") ||
          router.pathname.includes("gemiincome") ? (
            <FormControl
              className={classes.formControl}
              style={{ marginTop: 0 }}
              color="secondary"
            >
              <InputLabel id="income-type-label" color="secondary">
                Τύπος Εσόδων
              </InputLabel>
              <Select
                labelId="select-states"
                id="select-incometypes"
                multiple
                value={incomeType}
                onChange={(value) => handleIncomeType(value.target.value)}
                input={<Input id="select-income-type-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) =>
                      incomeTypes
                        .filter((option) => option.id === value)
                        .map((final) => (
                          <Chip
                            variant="outlined"
                            key={final.id}
                            label={final.name}
                            className={classes.chip}
                            classes={{
                              label: classesChip.colorChip4,
                              root: classesChip.colorChipBorder4,
                              deleteIcon: classesChip.colorChip4,
                            }}
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                            onDelete={handleDelete(0, final.id)}
                          />
                        ))
                    )}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {incomeTypes.map((option) => (
                  <MenuItem
                    classes={{
                      root: classes.menuItemRoot,
                      selected: classes.menuItemSelected,
                    }}
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <></>
          )}
          {router.pathname.includes("allincome") ? (
            <FormControl
              className={classes.formControl}
              style={{ marginTop: 0 }}
              color="secondary"
            >
              <InputLabel id="payments" color="secondary">
                Τρόπος πληρωμής
              </InputLabel>
              <Select
                labelId="select-payments"
                id="select-payments"
                multiple
                value={payment}
                onChange={(value) => handlePayment(value.target.value)}
                input={<Input id="select-payment-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) =>
                      payments
                        .filter((option) => option.id === value)
                        .map((final) => (
                          <Chip
                            variant="outlined"
                            key={final.id}
                            label={final.name}
                            className={classes.chip}
                            classes={{
                              label: classesChip.colorChip5,
                              root: classesChip.colorChipBorder5,
                              deleteIcon: classesChip.colorChip5,
                            }}
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                            onDelete={handleDelete(1, final.id)}
                          />
                        ))
                    )}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {payments.map((option) => (
                  <MenuItem
                    classes={{
                      root: classes.menuItemRoot,
                      selected: classes.menuItemSelected,
                    }}
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <></>
          )}
          {router.pathname.includes("allincome") ||
          router.pathname.includes("incomebytype") ? (
            <FormControl
              className={classes.formControl}
              style={{ marginTop: 0 }}
              color="secondary"
            >
              <InputLabel id="block" color="secondary">
                Μπλοκ / Σειρά
              </InputLabel>
              <Select
                labelId="select-block"
                id="select-block"
                multiple
                value={blockSer}
                onChange={(value) => handleBlock(value.target.value)}
                input={<Input id="select-block-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) =>
                      block
                        .filter((option) => option.name === value)
                        .map((final) => (
                          <Chip
                            variant="outlined"
                            key={final.name}
                            label={final.name}
                            className={classes.chip}
                            classes={{
                              label: classesChip.colorChip7,
                              root: classesChip.colorChipBorder7,
                              deleteIcon: classesChip.colorChip7,
                            }}
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                            onDelete={handleDelete(7, final.name)}
                          />
                        ))
                    )}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {block.map((option) => (
                  <MenuItem
                    classes={{
                      root: classes.menuItemRoot,
                      selected: classes.menuItemSelected,
                    }}
                    key={option.name}
                    value={option.name}
                  >
                    {option.name.concat(option.descr ? ' - ' + option.descr : '')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <></>
          )}
          {!router.pathname.includes("gemicases") ? (
            <FormControl
              className={classes.formControl}
              style={{ marginTop: 0 }}
              color="secondary"
            >
              <InputLabel id="state-label" color="secondary">
                Κατάσταση
              </InputLabel>
              <Select
                labelId="select-states"
                id="select-states"
                multiple
                value={state}
                onChange={(value) => handleState(value.target.value)}
                input={<Input id="select-state-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) =>
                      states
                        .filter((option) => option.id === value)
                        .map((final) => (
                          <Chip
                            variant="outlined"
                            key={final.id}
                            label={final.name}
                            className={classes.chip}
                            classes={{
                              label: classesChip.colorChip1,
                              root: classesChip.colorChipBorder1,
                              deleteIcon: classesChip.colorChip1,
                            }}
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                            onDelete={handleDelete(2, final.id)}
                          />
                        ))
                    )}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {states.map((option) => (
                  <MenuItem
                    classes={{
                      root: classes.menuItemRoot,
                      selected: classes.menuItemSelected,
                    }}
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <FormControl
              className={classes.formControl}
              style={{ marginTop: 0 }}
              color="secondary"
            >
              <InputLabel id="status-label" color="secondary">
                Κατάσταση
              </InputLabel>
              <Select
                labelId="select-status"
                id="select-status"
                multiple
                value={status}
                onChange={(value) => handleStatus(value.target.value)}
                input={<Input id="select-status-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) =>
                      gemiStatus
                        .filter((option) => option.id === value)
                        .map((final) => (
                          <Chip
                            variant="outlined"
                            key={final.id}
                            label={final.name}
                            className={classes.chip}
                            classes={{
                              label: classesChip.colorChip1,
                              root: classesChip.colorChipBorder1,
                              deleteIcon: classesChip.colorChip1,
                            }}
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                            onDelete={handleDelete(3, final.id)}
                          />
                        ))
                    )}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {gemiStatus.map((option) => (
                  <MenuItem
                    classes={{
                      root: classes.menuItemRoot,
                      selected: classes.menuItemSelected,
                    }}
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl className={classes.formControl} color="secondary">
            <InputLabel id="legal-forms-label" color="secondary">
              Νομικές Μορφές
            </InputLabel>
            <Select
              labelId="select-legal-forms"
              id="select-legal-forms"
              multiple
              value={legalForm}
              onChange={(value) => handleLegalForm(value.target.value)}
              input={<Input id="select-legal-forms-chip" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) =>
                    legalForms
                      .filter((option) => option.id === value)
                      .map((final) => (
                        <Chip
                          variant="outlined"
                          key={final.id}
                          label={final.name}
                          className={classes.chip}
                          classes={{
                            label: classesChip.colorChip2,
                            root: classesChip.colorChipBorder2,
                            deleteIcon: classesChip.colorChip2,
                          }}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          onDelete={handleDelete(4, final.id)}
                        />
                      ))
                  )}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {legalForms.map((option) => (
                <MenuItem
                  classes={{
                    root: classes.menuItemRoot,
                    selected: classes.menuItemSelected,
                  }}
                  key={option.id}
                  value={option.id}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!router.pathname.includes("gemicases") ? (<>
          </>
          ) : (
            <>
              <FormControl className={classes.formControl} color="secondary">
                <InputLabel id="perifereia-label" color="secondary">
                  Περιφέρειες
                </InputLabel>
                <Select
                  labelId="select-perifereia"
                  id="select-perifereia"
                  multiple
                  value={perifereia}
                  onChange={(value) => handlePerifereia(value.target.value)}
                  input={<Input id="select-perifereia-chip" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) =>
                        gemiPerifereia
                          .filter((option) => option.id === value)
                          .map((final) => (
                            <Chip
                              variant="outlined"
                              key={final.id}
                              label={final.name}
                              className={classes.chip}
                              classes={{
                                label: classesChip.colorChip3,
                                root: classesChip.colorChipBorder3,
                                deleteIcon: classesChip.colorChip3,
                              }}
                              onMouseDown={(event) => {
                                event.stopPropagation();
                              }}
                              onDelete={handleDelete(5, final.id)}
                            />
                          ))
                      )}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {gemiPerifereia.map((option) => (
                    <MenuItem
                      classes={{
                        root: classes.menuItemRoot,
                        selected: classes.menuItemSelected,
                      }}
                      key={option.id}
                      value={option.id}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl} color="secondary">
                <InputLabel id="transType-label" color="secondary">
                  Είδος Συναλλαγής
                </InputLabel>
                <Select
                  labelId="select-transType"
                  id="select-transType"
                  multiple
                  value={transType}
                  onChange={(value) => handleTransType(value.target.value)}
                  input={<Input id="select-transType-chip" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) =>
                        gemiTransType
                          .filter((option) => option.id === value)
                          .map((final) => (
                            <Chip
                              variant="outlined"
                              key={final.id}
                              label={final.name}
                              className={classes.chip}
                              classes={{
                                label: classesChip.colorChip4,
                                root: classesChip.colorChipBorder4,
                                deleteIcon: classesChip.colorChip4,
                              }}
                              onMouseDown={(event) => {
                                event.stopPropagation();
                              }}
                              onDelete={handleDelete(6, final.id)}
                            />
                          ))
                      )}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {gemiTransType.map((option) => (
                    <MenuItem
                      classes={{
                        root: classes.menuItemRoot,
                        selected: classes.menuItemSelected,
                      }}
                      key={option.id}
                      value={option.id}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            variant="outlined"
            className={classes.cancelButton}
          >
            ΑΚΥΡΩΣΗ
          </Button>
          <Button
            onClick={submitFilters}
            variant="outlined"
            classes={{
              disabled: classes.disabledButton,
            }}
            className={classes.submitButton}
            disabled={errorOnDates}
          >
            ΕΦΑΡΜΟΓΗ
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
