import Button from "@material-ui/core/Button";
import moment from "moment";
import "moment/locale/el";
import React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from "react-day-picker/moment";
import Helmet from "react-helmet";

export default class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: this.props.startdate,
      to: this.props.endDate,
    };
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
    this.props.handleStartDate(from);
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
    this.props.handleEndDate(to);
  }

  CustomOverlay({ classNames, selectedDay, children, ...props }) {
    const { disabledDays, selectedDays } = children.props;

    return (
      <div
        className={classNames.overlayWrapper}
        style={{ marginLeft: -100, textAlign: "center", zIndex: 15 }}
        {...props}
      >
        <div className={classNames.overlay}>
          <p>
            <Button
              color="secondary"
              variant="outlined"
              style={{ textTransform: "none", margin: 10 }}
              onClick={() => {
                children.props.onWeekClick(
                  "",
                  [
                    moment().startOf("week").toDate(),
                    ,
                    ,
                    ,
                    ,
                    ,
                    moment().endOf("week").toDate(),
                  ],
                  disabledDays,
                  selectedDays
                );
              }}
            >
              Τρέχουσα Εβδομάδα
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              style={{ textTransform: "none", margin: 10 }}
              onClick={() => {
                children.props.onWeekClick(
                  "",
                  [
                    moment().startOf("month").toDate(),
                    ,
                    ,
                    ,
                    ,
                    ,
                    moment().endOf("month").toDate(),
                  ],
                  disabledDays,
                  selectedDays
                );
              }}
            >
              Τρέχων Μήνας
            </Button>
          </p>
          {children}
        </div>
      </div>
    );
  }

  render() {
    const from = this.props.startDate;
    const to = this.props.endDate;
    const required = this.props.required;
    const width = this.props.width;
    const modifiers = { start: from, end: to };
    const placeholderFrom = this.props.placeholderFrom;
    const placeholderTo = this.props.placeholderTo;

    return (
      <div className="InputFromTo" style={{ display: "inline-flex" }}>
        <div>
          <div>{placeholderFrom}</div>
          <DayPickerInput
            inputProps={{ required: required }}
            localeUtils={MomentLocaleUtils}
            locale="el"
            value={from}
            overlayComponent={this.CustomOverlay}
            placeholder={placeholderFrom}
            format="DD/MM/YYYY"
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { after: to },
              toMonth: to,
              modifiers,
              numberOfMonths: 2,
              onDayClick: () => this.to.getInput().focus(),
              locale: "el",
              localeUtils: MomentLocaleUtils,
              showWeekNumbers: true,
              onWeekClick: (week, days) => {
                this.handleFromChange(days[0]);
                this.handleToChange(days[6]);
              },
            }}
            onDayChange={this.handleFromChange}
          />{" "}
        </div>
        <div
          style={{
            alignSelf: "flex-end",
            marginLeft: "16px",
            marginRight: "16px",
          }}
        >
          —{" "}
        </div>
        <div>
          <span className="InputFromTo-to">
            <div>{placeholderTo}</div>
            <DayPickerInput
              inputProps={{ required: required }}
              ref={(el) => (this.to = el)}
              value={to}
              overlayComponent={this.CustomOverlay}
              placeholder={placeholderTo}
              format="DD/MM/YYYY"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                selectedDays: [from, { from, to }],
                disabledDays: { before: from },
                modifiers,
                month: from,
                fromMonth: from,
                numberOfMonths: 2,
                locale: "el",
                localeUtils: MomentLocaleUtils,
                showWeekNumbers: true,
                onWeekClick: (week, days) => {
                  this.handleFromChange(days[0]);
                  this.handleToChange(days[6]);
                },
              }}
              onDayChange={this.handleToChange}
            />
          </span>
        </div>

        <Helmet>
          <style>{`
  .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .InputFromTo .DayPicker-Day {
    border-radius: 0 !important;
  }
  .InputFromTo .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .InputFromTo .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .InputFromTo .DayPickerInput-Overlay {
    width: 620px;
  }
  .InputFromTo-to .DayPickerInput-Overlay {
    margin-left: -100px;
  }
  .InputFromTo .DayPickerInput-Overlay {
    margin-left: ${width};
  }
  .InputFromTo-to .DayPickerInput input {
      width: 100px;
      height: 35px;
      border-radius: 5px;
      border-style: solid;
      border-width: 1px;
  }
  .InputFromTo .DayPickerInput input {
    width: 100px;
    height: 35px;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
   }
  .InputFromTo .DayPickerInput input:required {
    border-style: solid;
    border-width: 2px;
    border-color: #FF0033
   }
`}</style>
        </Helmet>
      </div>
    );
  }
}
